<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="12"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.25" height="16.244" viewBox="0 0 16.25 16.244"><path d="M220.023,1016.474l-4.167-3.35a3.986,3.986,0,0,0-4.963,0l-4.167,3.35a3.934,3.934,0,0,0-1.477,3.08v4.993a3.961,3.961,0,0,0,3.958,3.954H211.5v-3.954a1.875,1.875,0,0,1,3.75,0v3.954h2.292a3.961,3.961,0,0,0,3.958-3.954v-4.993A3.933,3.933,0,0,0,220.023,1016.474Zm.227,8.073a2.71,2.71,0,0,1-2.708,2.7H216.5v-2.7a3.125,3.125,0,0,0-6.25,0v2.7h-1.042a2.71,2.71,0,0,1-2.708-2.7v-4.993a2.689,2.689,0,0,1,1.009-2.106l4.167-3.35a2.729,2.729,0,0,1,3.4,0l4.167,3.35a2.689,2.689,0,0,1,1.009,2.106v4.993Z" transform="translate(-205.25 -1012.257)"/></svg>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
}
</script>
