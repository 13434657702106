export default [
  {
    title: 'Home',
    svg: '<svg width="14" height="14" viewBox="0 1 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.0665 11.3046V5.97124M6.8665 11.3046V2.77124M3.6665 11.3046V8.10457" stroke="#172747" stroke-width="1.06667" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    route: 'home',
  },
  {
    title: 'Recommendation',
    svg: '<svg width="13" height="13" viewBox="0 1 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_11684)"><path d="M7.46631 1.198H3.46631C3.20109 1.198 2.94674 1.30335 2.7592 1.49089C2.57167 1.67843 2.46631 1.93278 2.46631 2.198V10.198C2.46631 10.4632 2.57167 10.7176 2.7592 10.9051C2.94674 11.0926 3.20109 11.198 3.46631 11.198H9.46631C9.73153 11.198 9.98588 11.0926 10.1734 10.9051C10.361 10.7176 10.4663 10.4632 10.4663 10.198V4.198L7.46631 1.198Z" stroke="#172747" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.46631 1.198V4.198H10.4663" stroke="#172747" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.46631 6.698H4.46631" stroke="#172747" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.46631 8.698H4.46631" stroke="#172747" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.46631 4.698H4.96631H4.46631" stroke="#172747" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_1_11684"><rect width="12" height="12" fill="white" transform="translate(0.466309 0.197998)"/></clipPath></defs></svg>',
    route: 'recommendation',
    roleTitle: 'Recommendation',
  },
  {
    title: 'Pakages',
    svg: '<svg width="14" height="14" viewBox="0 1 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_11693)"><path d="M1.5332 10.0245L6.86654 12.6912L12.1999 10.0245M1.5332 7.35787L6.86654 10.0245L12.1999 7.35787M6.86654 2.02454L1.5332 4.6912L6.86654 7.35787L12.1999 4.6912L6.86654 2.02454Z" stroke="#172747" stroke-width="1.06667" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_1_11693"><rect width="12.8" height="12.8" fill="white" transform="translate(0.466309 0.957886)"/></clipPath></defs></svg>',
    route: 'pakages',
    roleTitle: 'pakages',
  },
  // {
  //   title: 'Transactions',
  //   svg: '<svg width="14" height="14" viewBox="0 1 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.71631 4.3512H5.88298" stroke="#172747" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.7509 4.89294H10.3414C9.37456 4.89294 8.59131 5.6204 8.59131 6.51794C8.59131 7.41549 9.3751 8.14294 10.3409 8.14294H11.7509C11.7964 8.14294 11.8186 8.14294 11.8375 8.14186C12.13 8.12399 12.3629 7.90786 12.3819 7.63649C12.383 7.61915 12.383 7.59803 12.383 7.55632V5.47957C12.383 5.43786 12.383 5.41674 12.3819 5.3994C12.3624 5.12803 12.13 4.9119 11.8375 4.89403C11.8191 4.89294 11.7964 4.89294 11.7509 4.89294Z" stroke="#172747"/><path d="M11.8225 4.8929C11.7803 3.8789 11.6448 3.25707 11.2066 2.8194C10.5723 2.18457 9.55076 2.18457 7.50814 2.18457H5.88314C3.84051 2.18457 2.81893 2.18457 2.18464 2.8194C1.5498 3.4537 1.5498 4.47528 1.5498 6.5179C1.5498 8.56053 1.5498 9.58211 2.18464 10.2164C2.81893 10.8512 3.84051 10.8512 5.88314 10.8512H7.50814C9.55076 10.8512 10.5723 10.8512 11.2066 10.2164C11.6448 9.77874 11.7808 9.1569 11.8225 8.1429" stroke="#172747"/><path d="M10.2114 6.51794H10.2168" stroke="#172747" stroke-linecap="round" stroke-linejoin="round"/></svg>',
  //   route: 'transactions',
  //   roleTitle: 'transactions',
  // },
  {
    title: 'Clients',
    svg: '<svg width="14" height="14" viewBox="0 1 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_11706)"><path d="M9.53285 11.4779V10.4113C9.53285 9.84547 9.30808 9.30285 8.90801 8.90277C8.50793 8.50269 7.96531 8.27793 7.39951 8.27793H3.13285C2.56705 8.27793 2.02443 8.50269 1.62435 8.90277C1.22427 9.30285 0.999512 9.84547 0.999512 10.4113V11.4779M12.7328 11.4779V10.4113C12.7325 9.93858 12.5752 9.47941 12.2856 9.10583C11.996 8.73225 11.5905 8.46543 11.1328 8.34726M8.99951 1.94726C9.4584 2.06476 9.86513 2.33164 10.1556 2.70583C10.446 3.08002 10.6037 3.54024 10.6037 4.01393C10.6037 4.48762 10.446 4.94784 10.1556 5.32203C9.86513 5.69622 9.4584 5.9631 8.99951 6.0806M7.39951 4.01126C7.39951 5.18947 6.44439 6.1446 5.26618 6.1446C4.08797 6.1446 3.13285 5.18947 3.13285 4.01126C3.13285 2.83306 4.08797 1.87793 5.26618 1.87793C6.44439 1.87793 7.39951 2.83306 7.39951 4.01126Z" stroke="#172747" stroke-width="1.06667" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_1_11706"><rect width="12.8" height="12.8" fill="white" transform="translate(0.466309 0.277954)"/></clipPath></defs></svg>',
    route: 'clients',
    roleTitle: 'clients',
  },
  // {
  //   title: 'Web-view',
  //   icon: '',
  //   route: { name: 'web-view', params: { packageID: '82716600-12fe-4865-8d79-22b1cf151419', optionID: '6M' } },
  //   roleTitle: 'dispensary-management',
  // },
  // {
  //   title: 'KYC Onboarding',
  //   icon: '',
  //   route: 'kyc-sign-up',
  //   roleTitle: 'dispensary-management',
  // },
  // {
  //   title: 'Drop Management',
  //   icon: 'SunsetIcon',
  //   svg: '<svg xmlns="http://www.w3.org/2000/svg" width="16.545" height="16.545" viewBox="0 0 16.545 16.545"><defs><style>.a{fill:#fff;}</style></defs><path class="a" d="M121.489,42.873c-.042.063-.972,1.409-4.7,1.978a10.156,10.156,0,0,1,3.051,2.5.394.394,0,0,1-.295.626,8.122,8.122,0,0,1-2.433-.339,11.511,11.511,0,0,1-2.259-.9v-.9a10.839,10.839,0,0,0,2.468,1.036,9.2,9.2,0,0,0,1.284.258,13.2,13.2,0,0,0-3.3-2.171.394.394,0,0,1,.125-.753,10.212,10.212,0,0,0,4.958-1.4,17.933,17.933,0,0,0-4.833.158.394.394,0,0,1-.27-.724,9.906,9.906,0,0,0,3.773-3.554,9.564,9.564,0,0,0-3.946,2.413.394.394,0,0,1-.683-.288,7.779,7.779,0,0,0-1.153-4.591,7.779,7.779,0,0,0-1.153,4.589.394.394,0,0,1-.682.288A9.621,9.621,0,0,0,107.5,38.7a9.91,9.91,0,0,0,3.774,3.554h0a.394.394,0,0,1-.269.724,18.005,18.005,0,0,0-4.834-.158,10.2,10.2,0,0,0,4.959,1.4.394.394,0,0,1,.125.753,13.225,13.225,0,0,0-3.3,2.167,10.755,10.755,0,0,0,4.931-2.059V43.444a.394.394,0,1,1,.788,0v7.881a.394.394,0,1,1-.788,0v-5.28a10.65,10.65,0,0,1-5.705,1.94,1.371,1.371,0,0,1-.169,0h0a.394.394,0,0,1-.295-.627,10.156,10.156,0,0,1,3.051-2.5c-3.723-.569-4.654-1.915-4.7-1.978a.394.394,0,0,1,.149-.567c.951-.486,2.887-.426,4.292-.3-2.829-2.13-3.01-3.73-3.017-3.806a.394.394,0,0,1,.457-.425,9.452,9.452,0,0,1,4.383,2.163,7.911,7.911,0,0,1,1.647-4.634.407.407,0,0,1,.591,0,7.911,7.911,0,0,1,1.647,4.634,9.454,9.454,0,0,1,4.383-2.166.394.394,0,0,1,.457.425c-.006.079-.187,1.676-3.017,3.807,1.406-.125,3.341-.185,4.292.3a.394.394,0,0,1,.149.567Z" transform="translate(-105.009 -35.175)"/></svg>',
  //   roleTitle: 'drop-management',
  //   children: [
  //     {
  //       title: 'Drop History',
  //       route: 'drop-management',
  //     },
  //     {
  //       title: 'Create Drop',
  //       route: 'drop-create',
  //     },
  //     {
  //       title: 'Inventory Update',
  //       route: 'inventory-management',
  //     },
  //   ],

  // }
]
