<template>
  <ul class="h-100 d-flex flex-direction">
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
    <li class="mt-auto nav-item">
      <b-link
        class="d-flex align-items-center justify-content-between logout"
        href="javascript:void(0)"
      >
      <span class="menu-title text-truncate font-weight-bolder" @click="$router.push('/profile')"> {{ userData.userName }} </span>
      <feather-icon
        @click="logout"
        icon="LogOutIcon"
        size="12"
      />
      </b-link>
    </li>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref, inject, computed } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import Vue from 'vue';

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup(props,context) {
    const router = context.root.$router;

    const $http = inject('$http');
    provide('openGroups', ref([]))

    const profile = async () => {
      console.log('profile call');
    }

    const userData = computed(() => store.getters["auth/getAuthUsers"]);

    const logout = async () => {
      await $http.post(`/advisor/logout`)
      .then((response) => {
        store.dispatch('auth/logout', response)
        router.push('/login')
      })
      .catch((error) => {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: `Error`,
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: error.response,
            closeOnClick: false,
            timeout: false,
          },
        })
      });
    }

    return {
      resolveNavItemComponent,
      logout,
      profile,
      userData
    }
  },
}
</script>
